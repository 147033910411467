@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  scroll-behavior: smooth;
  @apply h-full;
}

body {
  color: #444444;
  @apply h-full;
}

a {
  outline: 0;
}

/* Tailwind does not offer this customization in tailwind.config.js */
@media only screen and (min-width: 480px) and (max-width: 640px) {
  .container {
    max-width: 640px;
    width: 100%;
  }
}

.checked-bg-blue {
  background: #313e49;
  color: white !important;
  border-color: #313e49 !important;
}

.checked-b-green:checked + label > img {
  border-color: #25ae88 !important;
}

.checked-t-blue:checked + label {
  color: #313e49;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__triangle {
  display: none;
  visibility: collapse;
}

.react-datepicker__input-container > input {
  cursor: pointer;
  width: 100%;
}

.read-more-button {
  color: #ed1c24;
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
}
.gradient-hero {
  background: linear-gradient(
    90.33deg,
    #313e49 15.46%,
    rgba(49, 62, 73, 0.781752) 44.6%,
    rgba(49, 62, 73, 0.13) 70.72%
  );
}

.swiper-pagination-bullet-active {
  background-color: #e30b17;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.btn-disabled {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

.swiper-button-next,
.swiper-button-prev {
  color: rgba(49, 62, 73);
}

:root {
  --swiper-navigation-size: 16px;
}
.swiper-button-next.swiper-mini::after,
.swiper-button-prev.swiper-mini::after {
  font-size: 32px;
}

.slide-selected {
  position: relative;
  display: inline-block;
}

.slide-selected::before {
  position: absolute;
  content: '';
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
}

.slide-selected::after {
  position: absolute;
  right: 10px;
  bottom: 5px;
  content: '';
  transform: rotate(45deg);
  height: 25px;
  width: 12px;
  margin-left: 60%;
  border-bottom: 3px solid #e30b17;
  border-right: 3px solid #e30b17;
  border-radius: 2px;
}

.avoid-clicks {
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

.inputContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 300px;
  margin-top: 10px;
  margin-bottom: 8px;
  font-family: sofia-pro, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 17px;
  width: 100%;
}

.inputContainer input:disabled {
  color: #767676 !important;
}

.inputContainer input:not(:placeholder-shown) + label,
.labelText {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  color: #767676;
  top: -9px;
  left: 20px;
  background-color: #ffffff;
  opacity: 1;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  font-size: 15px;
  line-height: 15px;
  appearance: none;
}

.inputContainer input {
  border: 0.094rem solid #767676;
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  font-style: normal;
  font-size: 17px;
  color: #444444;
  line-height: 1.8em;
  padding-left: 20px;
  outline: 0px solid #767676;
}

.inputContainer input:placeholder-shown + label,
.labelTextHidden {
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  color: #767676;
  top: -9px;
  left: 20px;
  font-size: 16px;
  background-color: #ffffff;
  opacity: 0;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  font-size: 15px;
  line-height: 15px;
}

.inputContainer.error input {
  border-color: #e30b17;
}

/* .toggleContainer {
    box-sizing: revert !important;
}

.toggleContainer svg {
    padding-right: 10px;
} */

/* .buyForToggleOn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
    border-radius: 10px;
    background-color: #FFFFFF;
    width: 450px;
    height: 50px;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    cursor: pointer;
    line-height: 17px;
    color: #444444;
    border-color: #444444;
    border-style: solid;
    outline-style: none;
    width: 100%;
}

.buyForToggleOff {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 17px;
    width: 450px;
    height: 50px;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    cursor: pointer;
    line-height: 17px;
    color: #767676;
    border-color: #767676;
    border-style: solid !important;
    outline-style: none;
    width: 100%;
} */

.PhoneInput input {
  font-family: sofia-pro, -apple-system, BlinkMacSystemFont, Helvetica Neue, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 1.8em;
  outline: 0px solid #767676;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.markdown a {
  text-decoration: underline;
}
.markdown p {
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.markdown ol {
  margin-top: 0;
  margin-bottom: 1rem;
  display: block;
  list-style-type: decimal !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.markdown li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.img-preview {
  overflow: hidden;
}

.video-js {
  width: 100%;
  padding-top: calc(1080 / 1920 * 100%);
  border-radius: 10px;
}

.uppy-StatusBar:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 10px !important;
  background-color: #eaeaea;
  border-radius: 10px;
}
.uppy-StatusBar-progress {
  height: 10px !important;
  border-radius: 10px;
}

#aboutUsOurJourneyContainer::-webkit-scrollbar {
  display: none !important;
}

#aboutUsOurJourneyWrapper::-webkit-scrollbar {
  display: none !important;
}

.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 200ms, -webkit-filter 200ms;
}
.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}
.fade-box .lazyload,
.fade-box .lazyloading {
  opacity: 0;
  transition: opacity 200ms;
}
.fade-box img.lazyloaded {
  opacity: 1;
}

.paypal-overlay {
  animation: fadeout 1s;
  -webkit-animation: fadeout 1s; /* Chrome, Safari, Opera */
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.swiper-container-horizontal > .swiper-scrollbar {
  left: inherit !important;
  bottom: 1rem;
}

.swiper-scrollbar-drag {
  background-color: #3e77b0;
}

@keyframes fadeout {
  from {
    max-height: 200px;
    opacity: 0.2;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.swiper-container {
  position: relative;
}

.sku-swiper-container * .swiper-slide {
  min-width: 215px;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0); 
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; 
  width: 1px;
}

/* Web Spotlight styles */
ksl-highlight {
  border: 2px dashed #da521f;
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) { img[loading="lazy"] { clip-path: inset(0.6px) } }
